import {Link, Outlet} from "@remix-run/react";
import Logo from "#app/components/layout/logo.tsx";
import {isDemoMode} from "#app/utils/misc.tsx";

export default function Auth() {
  const demoMode  = isDemoMode()

  return (
    <div className="flex-1 grid lg:grid-cols-2">
      <div className="flex flex-col">
        <header className="container">
          <Link to={demoMode ? 'https://www.supermerchado.ch/' : '/'} className="block mt-12">
            <Logo className="w-[169px]"/>
          </Link>
        </header>
        <Outlet/>
      </div>
      <div className="hidden lg:block">
        <div className="sticky top-0">
          <img src="/images/login-bg.jpg" className="object-cover w-full h-screen" alt="Die All-in-One Plattform für dein Merchandising"/>
        </div>
      </div>
    </div>
  )
}